import React from 'react'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'

const RepresentacionLegal = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Representación legal"
        description="Estableciendo representación legal en Polonia"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"
      />
      <header className="top-representacion">
        <NavMenu />
      </header>
      <div className="container container-flex">
        <main role="main">
          <div className="text-4">
            <h1 className="title-6">Representación legal</h1>
            <p>
              {' '}
              La representación legal no es otra cosa que un apoderamiento para
              el recibimiento de correspondencia en tu nombre.
            </p>

            <p>
              Las autoridades polacas requieren que cada solicitante de
              confirmación de ciudadanía polaca debe indicar a un residente en
              Polonia para recibir la correspondencia sobre su caso en su
              nombre. No necesitas contratar abogados para ese fin, es
              suficiente con que indiques nuestra dirección en el apoderamiento
              para que la correspondencia llegue de manera segura a nuestras
              manos y posteriormente a las tuyas.
            </p>

            <p>
              Puede que lo único que necesites es una dirección en Polonia y los
              demás trámites los harás por medio del cónsul polaco de país de tu
              residencia – ofrecemos tarifas muy económicas para que puedas
              acceder nada más a nuestra dirección de correo y nos encargaremos
              de hacerte saber qué documentación te ha llegado desde las
              autoridades polacas.
            </p>

            <p>
              Si decides realizar todo el trámite de confirmación de ciudadanía
              con nosotros el precio de apoderamiento para el recibimiento de
              correspondencia está incluido en la tasa.
            </p>
          </div>
        </main>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default RepresentacionLegal
