import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import ConfirmacionCiudadania from './components/pages/ConfirmacionCiudadania'
import Home from './components/pages/Home'
import Nosotros from './components/pages/Nosotros'
import RepresentacionLegal from './components/pages/RepresentacionLegal'
import TraduccionDocumentos from './components/pages/TraduccionDocumentos'
import TramiteRegistro from './components/pages/TramiteRegistro'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Faq from './components/pages/Faq'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/confirmacion-de-ciudadania">
          <ConfirmacionCiudadania />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/nosotros">
          <Nosotros />
        </Route>
        <Route path="/representacion-legal">
          <RepresentacionLegal />
        </Route>
        <Route path="/traduccion-documentos">
          <TraduccionDocumentos />
        </Route>
        <Route path="/tramite-registro-civil">
          <TramiteRegistro />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}

const WrappedApp = () => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
    language="es-419"
    useEnterprise={true}
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "body" // optional, default to "head", can be "head" or "body",
    }}
  >
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </GoogleReCaptchaProvider>
)

export default WrappedApp
