import React from 'react'
import MetaTags from 'react-meta-tags'
import JSONLD from '../atoms/JSON-LD'

const MetaComponent = (props) => {
    return (
        <MetaTags>
            <title>{props.title}</title>
            <meta property="og:type" content="website" />
            <meta property="og:description" content={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={props.image} />
            {
                props.data && <JSONLD data={props.data}/>
            }
        </MetaTags>
    )
}

export default MetaComponent