import React from 'react'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'

const TraduccionDocumentos = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Traducción de documentos al idioma polaco"
        description="Traducciones a precios competitivos al idioma polaco"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"
      />
      <header className="top-traduccion">
        <NavMenu />
      </header>
      <div className="container container-flex">
        <main role="main">
          <div className="text-4">
            <h1 className="title-6">Traducción de documentos</h1>
            <p>
              {' '}
              Para el trámite de confirmación de ciudadanía polaca todos tus
              documentos extranjeros tienen que estar traducidos al idioma
              polaco. Debes tomar en cuenta que las autoridades polacas solo
              aceptan traducciones certificadas por traductores jurados
              registrados en Polonia o por cónsules polacos.{' '}
            </p>
            <p>
              Para ahorrarte dinero y tiempo puedes encargarnos las
              traducciones, ya que contamos con equipos de traductores jurados,
              ofreciéndote precios competitivos y tiempos de realización
              razonables. Adicionalmente, si necesitas ayuda con los formularios
              consulares u otras solicitudes ante el cónsul te podremos ayudar a
              diligenciar el formulario.
            </p>
          </div>
        </main>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default TraduccionDocumentos
