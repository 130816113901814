import React from 'react'
import Container from '@material-ui/core/Container'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'

const ConfirmacionCiudadania = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Confirmación de ciudadanía polaca"
        description="Encuentra aquí la documentación necesaria para confirmar la ciudadanía polaca"
        image="https://ciudadaniadepolonia.pl/confirmacion-de-ciudadania"
      />
      <header className="top-confirmacion">
        <NavMenu />
      </header>
      <Container maxWidth="lg">
        <main role="main">
          <div className="text-4">
            <h1 className="title-6">Confirmación de ciudadanía polaca</h1>
            <p>
              {' '}
              El procedimiento de confirmación de ciudadanía varía en función de
              dos situaciones: Si dispones de documentos que acrediten la
              ciudadanía polaca de tus ancestros. Si eres capaz de probar que en
              tu línea familiar se mantuvo la cadena de ciudadanía polaca.{' '}
            </p>

            <h3>Criterios</h3>
            <p>
              Debes tener en cuenta que la descendencia polaca se cuenta hasta
              tres generaciones atrás, es decir, hasta tu bisabuelo o bisabuela,
              siempre y cuando tu ancestro no haya perdido la ciudadanía polaca
              antes que tu nacieras. Recuerda, que cada caso es diferente y es
              nuestra responsabilidad la de evaluar si calificas a la ciudadanía
              polaca y cuál es la mejor manera de obtenerla. Antes de
              comprometernos a gestionar tu solicitud, estudiaremos tu caso
              meticulosamente sin ningún costo.
            </p>

            <h3>Documentación imprescindible</h3>
            <ul>
              <li>
                Documentación original polaca de tu ancestro, por ejemplo:
                pasaporte, documento de identidad, libreta militar, registro de
                votante, acta civil de nacimiento, matrimonio o defunción,
                certificado de bautismo, empadronamiento, inscripciones de
                bienes raíces, escrituras notariales, antecedentes penales,
                certificados académicos, etc.
              </li>
              <li>
                Constancia de entidades de residencia de tu ancestro en caso de
                que haya adquirido otra ciudadanía, por ejemplo: cámaras
                electorales, autoridades migratorias etc.
              </li>
              <li>
                Copia de documento de identidad tuyo (la copia debe ser
                certificada – ver apartado “Apostillas, copias certificadas y
                traducciones”).
              </li>
              <li>
                Tus certificados civiles de nacimiento y -si aplica-, de
                matrimonio (si naciste fuera de la Unión Europea deben estar
                apostilladas – ver apartado “Apostillas, copias certificadas y
                traducciones”).
              </li>
              <li>
                Dos copias de solicitud de confirmación de ciudadanía polaca.
              </li>
              <li>
                Apoderamiento para la representación legal (para que podamos
                presentar la solicitud de confirmación de ciudadanía en tu
                nombre)
              </li>
              <li>
                Apoderamiento para el recibimiento de correspondencia en tu
                nombre{' '}
              </li>
              <li>Dos fotos tamaño carnet (con fondo blanco, 3,5 x 4,5 cm)</li>
            </ul>

            <h3>Apostillas, copias certificadas y traducciones</h3>
            <p>
              Toda la documentación que se ha de presentar ante la oficina del
              Voivodato (Provincia) en Polonia debe estar preparada en idioma
              polaco. Para ello, elaboraremos tu solicitud basándonos en la
              información que nos proporciones en español. Recuerda los
              siguientes requisitos: Todos los documentos procedentes de estados
              civiles debes apostillarlos antes de entregárnoslos. Nosotros te
              ayudaremos a identificar que otros documentos necesitarán de una
              Apostilla y dónde la puedes obtener en tu país de residencia; La
              traducción de los documentos elaborados en español nos la puedes
              encargarnos a nosotros, ya que contamos con equipos de traductores
              jurados, ofreciéndote precios competitivos y tiempos de
              realización razonables. Las traducciones de documentos se cobran
              aparte del trámite de solicitud de confirmación de ciudadanía.
              Todos los documentos originales que comprueben tu derecho a la
              ciudadanía polaca nos los tienes que enviar a Polonia, ya que su
              presentación es imprescindible a la hora de entregar la solicitud
              de confirmación de ciudadanía. Si tienes documentos originales que
              prefieres no enviar a Polonia (por valor sentimental o cualquier
              otra razón), puedes certificar su copia ante el cónsul polaco y
              enviarnos nada más las copias certificadas. Después de presentar
              tu solicitud de confirmación de ciudadanía ante las autoridades
              polacas, los originales te los reenviaremos de vuelta, ya que el
              Voivodato se queda solamente con las copias.{' '}
            </p>

            <h3>Búsqueda de documentos en archivos</h3>

            <p>
              Esta es probablemente la parte más complicada del trámite que nos
              la dejarás a nosotros. Si tienes dificultad en encontrar
              documentación polaca de tus descendientes, es donde nosotros
              entramos en acción. A veces es necesario realizar varios viajes o
              contactar con entidades.{' '}
            </p>

            <h3>Tiempo</h3>
            <p>
              El tiempo de trámite de la confirmación de ciudadanía polaca varía
              dependiendo de cada caso. Desde la presentación de la solicitud
              ante las autoridades polacas hasta recibir la certificación,
              pueden pasar de 2 a 6 meses. A veces sucede que el Voivodato pide
              presentar algún documento adicional que acredite la ciudadanía
              polaca. No cabe duda, que el tiempo se acorta si decides hacer los
              trámites con nuestra agencia, ya que residimos en Polonia y no se
              pierde tiempo, por ejemplo, en envíos. Además, nos ocupamos de tu
              solicitud personalmente sin ningún tipo de intermediarios.
            </p>

            <h3>Pasaporte polaco</h3>
            <p>
              Una vez obtenida la confirmación de ciudadanía polaca, podrás
              proceder con el registro de tus certificados civiles en Polonia
              (acá puedes ver en que consiste el trámite y como te podemos
              ayudar) y para concluir solicitar tu primer pasaporte polaco ante
              el cónsul polaco (también podemos ayudarte a rellenar la
              solicitud).
            </p>

            <h3>Derechos y obligaciones</h3>
            <p>
              Las personas portadoras de un pasaporte polaco serán consideradas
              polacas en el territorio polaco, independientemente de poseer
              otras ciudadanías. En la actualidad, la legislación de la mayoría
              de los países del mundo permite doble ciudadanía, por lo cual, la
              obtención de la polaca no supone ningún obstáculo. Debes saber que
              cualquier portador de pasaporte polaco puede circular libremente
              por el territorio Schengen, trabajar o estudiar en cualquier otro
              país de la Unión Europea.{' '}
            </p>

            <h3>Base legal</h3>
            <p>
              Ese ámbito lo regula la Ley de ciudadanía polaca del 2 de abril de
              2009 (Boletín Oficial del 2018 no. 1829).
            </p>
          </div>
        </main>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default ConfirmacionCiudadania
