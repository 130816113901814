import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ContactForm from './ContactForm'
import Grid from '@material-ui/core/Grid'

const Footer = () => {
  return (
    <footer>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <Box style={{textAlign: 'center'}}>
              <h1 id="contactenos" className="title-5">
                Contáctenos
              </h1>
              <p>
                Tel +48 798522602
                <br /> info@ciudadaniadepolonia.pl
                <br />
              </p>
              <div>
                Horario: <br />
                Lunes-Viernes: 9am-5pm <br />
                Sábado: 10am-1pm
                <br />
                Hora de Polonia*
                <br />
                <span className="small-text">
                  (*)Teniendo en cuenta cualquier diferencia horaria, contáctenos de
                  inmediato y le responderemos a la mayor brevedad. Podemos
                  establecer reuniones virtuales en el programa de su preferencia.
                </span>
                <Box m={5} >
                <div className="social-media">
                  <a href="https://wa.link/qw4p8j">
                    <img src="img/whatsapp-logo.png" alt="whatsapp-logo" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCH19G8az_kSRwX22gieJpxA/videos">
                    <img src="img/youtube-logo.png" alt="youtube-logo" />
                  </a>
                  <a href="https://www.facebook.com/ciudadaniadepolonia">
                    <img src="img/facebook-logo.png" alt="facebook-logo" />
                  </a>
                </div>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}  md={4}>
            <Box style={{textAlign: 'center'}}>
              <img src="img/logo.jpeg" alt="logo AMLAT" />
            </Box>
           </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ContactForm />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="col-footer-4"> © 2021 AMLAT Consulting </div>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
