import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

const useStyles = makeStyles((theme) => ({
  bar: {
    backgroundColor: 'transparent',
    '& .MuiButton-label': {
      color: 'white',
    },
  },
  scrolledBar: {
    backgroundColor: 'black',
    opacity: '0.8',
  },
  menuItems: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}))

const NavMenu = () => {
  const classes = useStyles()
  const history = useHistory()
  const scrolling = useScrollTrigger({ disableHysteresis: true })

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [dropMenuItems, setDropMenuItems] = React.useState(null)
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleClick = (val) => {
    history.push(`/${val}`)
  }

  const showMenu = (event, items) => {
    setDropMenuItems(items)
    setAnchorEl(event.currentTarget)
  }

  return (
    <React.Fragment>
      <SwipeableDrawer
        className={clsx({
          [classes.bar]: true,
          [classes.scrolledBar]: true,
        })}
        anchor="left"
        open={drawerOpen}
        onOpen={() => setDrawerOpen(true)}
        onClose={() => setDrawerOpen(false)}
      >
        <List>
          <ListItem button component="a" href="/">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component="a" href="/nosotros">
            <ListItemText primary="Nostros" />
          </ListItem>
          <ListItem button component="a" href="/confirmacion-de-ciudadania">
            <ListItemText primary="Confirmación ciudadanía" />
          </ListItem>
          <ListItem button component="a" href="/representacion-legal">
            <ListItemText primary="Representación legal" />
          </ListItem>
          <ListItem button component="a" href="/traduccion-documentos">
            <ListItemText primary="Traducción de documentos" />
          </ListItem>
          <ListItem button component="a" href="/tramite-registro-civil">
            <ListItemText primary="Trámite de registro civíl" />
          </ListItem>
          <ListItem button component="a" href="/faq">
            <ListItemText primary="Preguntas frecuentes" />
          </ListItem>
        </List>
      </SwipeableDrawer>
      <AppBar
        position="fixed"
        className={clsx({
          [classes.bar]: true,
          [classes.scrolledBar]: scrolling,
        })}
      >
        <Toolbar>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Container maxWidth="lg" className={classes.menuItems}>
              {dropMenuItems && (
                <Menu
                  id="context-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {dropMenuItems.map((i, idx) => (
                    <MenuItem key={`menu-item-${idx}`} onClick={() => handleClick(i.value)}>
                      {i.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
              <Button href="/">Home</Button>
              <Button href="/nosotros">Nosotros</Button>
              <Button
                onClick={(ev) =>
                  showMenu(ev, [
                    {
                      label: 'Confirmación ciudadanía',
                      value: 'confirmacion-de-ciudadania',
                    },
                    {
                      label: 'Representación legal',
                      value: 'representacion-legal',
                    },
                    {
                      label: 'Traducción documentos',
                      value: 'traduccion-documentos',
                    },
                    {
                      label: 'Trámite de registro civíl',
                      value: 'tramite-registro-civil',
                    },
                  ])
                }
              >
                Servicios
              </Button>
              <Button href="/faq">Preguntas Frecuentes</Button>
              <Button href="#contactenos">Contacto</Button>
            </Container>
          </Hidden>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default NavMenu
