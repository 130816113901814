import React from 'react'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'

const TramiteRegistro = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Trámite de registro civil en Polonia"
        description="Tramitamos tu registro civil cuando cuentas con la confirmación de ciudadanía polaca"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"
      />
      <header className="top-tramite">
        <NavMenu />
      </header>
      <div className="container container-flex">
        <main role="main">
          <div className="text-4">
            <h1 className="title-6">Trámite de registro civil</h1>
            <p>
              {' '}
              Es un servicio adicional que ofrecemos dado a que residimos en
              Polonia y los trámites de registro de certificaciones civiles es
              más ágil y menos costoso en comparación con su gestión desde el
              extranjero. Por lo general el registro de estos certificados se
              dan cuando ya tengas tu confirmación de ciudadanía polaca con el
              fin de obtener tu pasaporte polaco.
            </p>

            <p>
              Nos puedes contactar en casos de:
              <ul>
                <li>
                  Registro de certificados de nacimiento y matrimonio emitidos
                  en el extranjero para su registro en Polonia
                </li>
                <li>
                  Extracción de certificados polacos de nacimiento, matrimonio y
                  defunción{' '}
                </li>
                <li>
                  Cambio de datos en cualquiera de los documentos de registro
                  civil
                </li>
              </ul>
            </p>
            <p>
              Para cualquiera de los casos arriba mencionados debes adjuntar el
              apoderamiento, en el que nos inicarás como persona responsable del
              trámite – en cuanto nos contactes te mandaremos la versión
              adecuada del apoderamiento y te explicaremos el trámite con más
              detalle.
            </p>
          </div>
        </main>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default TramiteRegistro
