import React from 'react'
import Container from '@material-ui/core/Container'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'


const Nosotros = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Ciudadanía de Polonia: Nosotros"
        description="Somos un equipo de expertos en tramitación de ciudadanía polaca con experiencia laboral en América Latina de más de 25 años"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"

      />
      <header className="top-nosotros">
        <div className="container">
          <NavMenu />
        </div>
      </header>
      <div className="container container-flex">
        <main role="main">
          <Container maxWidth="lg">
            <h1 className="title-6">Nosotros</h1>
            <p>
              {' '}
              Si eres descendiente polaco y hablas español, acabas de encontrar
              tu página perfecta; si cumples con los requisitos para la
              confirmación de la ciudadanía polaca, tienes una gran probabilidad
              de formar parte de nuestra comunidad y mantener viva la memoria e
              historia de este gran país.{' '}
            </p>

            <p>
              Somos un equipo de expertos en tramitación de ciudadanía polaca
              con experiencia laboral en América Latina de más de 25 años. Al
              residir en Polonia los trámites se pueden realizar, no solo de
              forma más económica, pero también más rápida. Para ello, necesitas
              de un representante legal con experiencia en preparar la
              documentación requerida. Trabajamos de forma personalizada,
              dedicada y diligente; respondemos a tus dudas de manera inmediata
              y te mantendremos al tanto sobre el proceso de tus trámites.
              Tendrás acceso a nuestro equipo por cualquier medio de
              comunicación.
            </p>
            <p>
              {' '}
              Una vez obtengas tu pasaporte polaco, podrás gozar de tus derechos
              y cumplir con las obligaciones; por un lado, podrás viajar y vivir
              en la Unión Europea, participar en elecciones nacionales,
              organizadas por los consulados polacos en el exterior, pero
              también te animamos a que conozcas la historia de Polonia, sus
              costumbres, idioma, literatura y artes. Podrás profundizar esos
              conocimientos a través de diferentes entidades que proponemos
              entre nuestros enlaces.
            </p>
          </Container>
        </main>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Nosotros
