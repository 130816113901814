import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '3.6rem',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '2.5rem',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
    p: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
})

export default responsiveFontSizes(theme)
