import React from 'react'
import Banner from '../molecules/Banner'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'
import Typography from '@material-ui/core/Typography'
import YouTubeVideo from '../molecules/YouTubeVideo'

const Home = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Ciudadanía de Polonia"
        description="Prestamos servicios para la confirmación y adquisición de la ciudadanía polaca de manera remota y confiable"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"
      />
      <NavMenu />
      <Banner src="/img/home-1.jpg">
        <Typography variant="h1">CIUDADANÍA DE POLONIA</Typography>
        <Typography variant="h2">Sé parte de la historia</Typography>
      </Banner>
      <Container maxWidth="lg">
        <main role="main">
          <Grid container>
            <Grid container item justify="space-between" xs={12}>
              <Grid item xs={12} lg={6}>
                <Box p={4} pt={8}>
                  <Typography variant="h5" style={{ fontWeight: '600' }}>
                    Nos especializamos en la confirmación de la ciudadanía
                    polaca; nuestros especialistas te ayudarán con el trámite de
                    tu ciudadanía de manera remota y confiable, a través de una
                    comunicación clara y asesoría detallada. Conoce nuestra
                    oferta de servicios adicionales y no esperes más con tu
                    trámite.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} style={{ textAlign: 'center' }}>
                <Box p={4}>
                  <YouTubeVideo
                    id="main-vid"
                    height="315"
                    width="560"
                    img="img/home-2.jpg"
                    videoId="D3pe4N11np8"
                    playerVars={{
                      autoplay: 0,
                      rel: 0,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} lg={3}>
                <div className="col col-1">
                  <h3 className="title-3">Confirmación de ciudadanía polaca</h3>
                  <p className="text-1">
                    Aparte de preparar la solicitud, también te ayudaremos a
                    comprobar que en tu línea familiar se mantuvo la cadena de
                    ciudadanía polaca y a encontrar documentos que acrediten la
                    ciudadanía polaca de tus antepasados.
                  </p>
                  <a href="confirmacion.html">Leer más </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <div className="col col-2">
                  <h3 className="title-3">Representación legal </h3>
                  <p className="text-1">
                    {' '}
                    Para poder iniciar el trámite de confirmación de ciudadanía
                    polaca es imprescindible tener un representante legal con
                    dirección en Polonia para recibir la correspondencia. Te
                    ofrecemos ese servicio si no cuentas con un residente ni
                    dirección polaca.
                  </p>
                  <a href="representacion.html"> Leer más </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <div className="col col-3">
                  <h3 className="title-3">Traducción de documentos</h3>
                  <p className="text-1">
                    {' '}
                    Para el trámite de confirmación de ciudadanía polaca todos
                    tus documentos extranjeros tienen que estar traducidos al
                    idioma polaco. Contamos con equipos de traductores jurados,
                    ofreciéndote precios competitivos y tiempos de realización
                    razonables.
                  </p>
                  <a href="traduccion.html"> Leer más </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <div className="col col-4">
                  <h3 className="title-3">Trámite de registro civil</h3>
                  <p className="text-1">
                    {' '}
                    Nos puedes encargar el registro y extracción de documentos
                    procedentes de registros civiles (nacimiento, matrimonio y
                    defunción), al igual que el cambio de datos en esos
                    documentos. Desde Polonia ese procedimiento es más económico
                    y más ágil.
                  </p>
                  <a href="extraccion.html"> Leer más </a>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                height="100%"
                width="100%"
                src="img/home-3.jpg"
                alt="Acerca de nosotros"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={{height: "100%", width: "100%"}}>
                <div className="nosotros">
                  <h1 className="title-4">Nosotros</h1>
                  <p className="text-2">
                    {' '}
                    Somos un equipo de expertos en tramitación de ciudadanía
                    polaca con experiencia laboral en América Latina de más de
                    25 años. Al residir en Polonia los trámites se pueden
                    realizar, no solo de forma más económica, sino también más
                    rápida.
                  </p>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="testimonial">
              <Box p={10}>
                <Typography variant="h6">
                  <strong>"</strong>Quiero expresar nuevamente mi eterno
                  agradecimiento por la magnífica e insuperable labor y gestión
                  impecable que se realizó a través del servicio de
                  Representación Legal, para mi y para mi familia, y haber
                  puesto tanta eficiencia, efectividad y voluntad para lograr un
                  resultado final satisfactorio en la obtención de mi ciudadanía
                  Polaca.
                  <br />
                  Este trámite que llevaba intentando realizar durante más de 20
                  años, en los que, con los procesos y documentación exigidos,
                  tan variados y a veces difíciles, creí que no podría nunca
                  obtener mi nacionalidad Polaca. Y se logró siguiendo de cerca
                  el curso del proceso. <br />
                  Se logró y su valioso esfuerzo valió la pena y hoy puedo,
                  junto con mi familia, aplaudir su labor cumplida.
                  <strong>"</strong>
                  <br />
                  Frida Scharf de Sanabria, Colombia{' '}
                </Typography>
              </Box>
            </div>
          </Grid>
        </main>
        <Footer />
      </Container>
    </React.Fragment>
  )
}

export default Home
