import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 'bold',
    '& h1': {
      color: 'white'
    },
    '& h2': {
      color: 'white'
    }
  },
}))

const Banner = ({children, src}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      className={classes.root}
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        height: '40em',
      }}>
      <Grid item xs={12} md={6}>
        <Box p={4} style={{textAlign: 'center'}}>{children}</Box>
      </Grid>
    </Grid>
  )
}

export default Banner
