import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

// Fluid video as seen on
// https://css-tricks.com/fluid-width-video/
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    /* falls back to 16/9, but otherwise uses ratio from HTML */
    paddingBottom: 'calc(var(--aspect-ratio, .5625) * 100%)',
    height: 0,
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }
  }
}))

const YouTubeVideo = ({onPlayerReady, id, img, ...props}) => {
  const classes = useStyles()
  const [player, setPlayer] = useState()
  const [imageVisible, setImageVisible] = useState(true)

  const playerReady = () => {
    setPlayer(new YT.Player(`youtube-player-${id}`, props))
  }

  const playVideo = () => {
    setImageVisible(false)
    player.playVideo()
  }

  useEffect(() => {
    if (!window.YT) {
      // load the IFrame Player API code asynchronously if it is not loaded yet
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      window.onYouTubeIframeAPIReady = playerReady
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      playerReady()
    }
  }, [])

  return (
    <div className={classes.root}>
      {img && (
        <img
          id={`video-img-${id}`}
          src={img}
          style={{ position: 'absolute', zIndex: 1, display: imageVisible ? 'block' : 'none' }}
          onClick={playVideo}
        />
      )}
      <div id={`youtube-player-${id}`} />
    </div>
  )

}

export default YouTubeVideo
