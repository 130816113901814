import React from 'react'
import Container from '@material-ui/core/Container'
import Footer from '../organisms/Footer'
import MetaComponent from '../molecules/MetaComponent'
import NavMenu from '../organisms/NavMenu'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const data= {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "¿Quién puede aplicar a la confirmación de la ciudadanía polaca?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Todas aquellas personas que son descendientes de polacos; es decir, cuando uno de los padres, uno de los abuelos o bisabuelos tuvieron ciudadanía polaca y no renunciaron a ella ante alguna autoridad polaca."
    }
  }, {
    "@type": "Question",
    "name": "¿Dónde se realiza el trámite?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Hay dos opciones: ante un cónsul polaco o ante una agencia que se especializa en temas de tramitación de la ciudadanía polaca; vale la pena destacar, que el trámite ante el cónsul generalmente puede durar más y el cónsul siempre requerirá que se asigne un representante legal en Polonia para recibir correspondencia referente a su caso; esto ocurre porque las autoridades polacas no envían documentación referente al trámite fuera de las fronteras polacas."
    }
  }, {
    "@type": "Question",
    "name": "¿Cuándo debo realizar el trámite?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "En cualquier momento se puede realizar. El derecho a la ciudadanía polaca no se pierde en ningún momento a lo largo de la vida."
    }
  }, {
    "@type": "Question",
    "name": "¿Por qué no tendría que realizar el trámite de confirmación de ciudadanía polaca ante un cónsul polaco?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Por varias razones: el trámite realizado por AMLAT Consulting será más ágil, ya que nuestra sede se encuentra en Varsovia -es decir-, en la ciudad en la que se encuentra el Voivodato (distrito administrativo), responsable de tramitar su ciudadanía polaca. Por ende, el trámite es también más económico y con un trato personalizado. Además, desde Polonia podemos encontrar con mas facilidad documentos relativos a su ascendiente que certifiquen la ciudadanía polaca. Los cónsules tienen posibilidades muy limitadas para realizar esas búsquedas."
    }
  }, {
    "@type": "Question",
    "name": "¿Mis hijos podrán heredar la ciudadanía polaca?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Si. De hecho, si usted recibiera su confirmación de ciudadanía polaca antes que sus hijos cumplieran los 18 años de edad, su trámite será automático –basta con registrar las partidas de nacimiento en Polonia y con base en eso podrán acceder a pasaportes polacos–. En el caso de que ya hayan cumplido la mayoría de edad, tendrán que hacer su trámite de confirmación de ciudadanía polaca similar al suyo."}
  },{
    "@type": "Question",
    "name": "¿Mi cónyuge podrá heredar la ciudadanía polaca?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, la ciudadanía polaca es hereditaria ya que se rige por el derecho de sangre. En caso de que su cónyuge haya tenido ascendientes polacos, tendrá que realizar su trámite de confirmación de ciudadanía polaca por aparte."
    }
  },{
    "@type": "Question",
    "name": "¿Cuánto tiempo tarda el proceso de confirmación de ciudadanía polaca?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "El tiempo de proceso es muy relativo y depende principalmente de la documentación que certifique su descendencia. El trámite puede durar desde un par de meses hasta 2-3 años."
    }
  },{
    "@type": "Question",
    "name": "¿Por qué necesito un representante legal?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Independientemente si el trámite se realiza por medio de un cónsul o de AMLAT Consulting, la figura del representante legal es requerida por la ley polaca. En otras palabras, se necesita a una persona física residente en Polonia para que reciba la correspondencia y documentación relativa a su trámite de confirmación de ciudadanía polaca. Sin un representante legal en Polonia no es posible iniciar este trámite."
    }
  },{
    "@type": "Question",
    "name": "¿Qué documentos certifican que soy descendiente de polacos?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Lo ideal es tener un pasaporte polaco del ascendiente, libreta militar o cualquier otro documento de identidad original. Hay muchísimos casos en los que las personas no tienen ninguna prueba, sin embargo, es posible probar el vínculo con el ascendiente por medio de otros documentos. AMLAT Consulting les ayudará a ubicar documentos de sus relativos existentes en los archivos polacos. Para ello realizamos viajes por el territorio polaco en búsqueda de cualquier prueba de existencia de sus familiares."
    }
  },{
    "@type": "Question",
    "name": "¿Qué beneficios me ofrece un pasaporte polaco?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "¡Un sinfín! Podrá viajar a Polonia y a los países del territorio Schengen (es decir, la mayoría del territorio de la Unión Europea) sin limites y será considerado residente de la UE en cualquier país que pertenece a la comunidad. Esto abre posibilidades de estudiar, trabajar, hacer o abrir negocios en Polonia y en el resto de la UE. Polonia es un país que ofrece su riqueza histórica, económica y cultural, de la cual siendo polaco podrá disfrutar plenamente."
    }
  },{
    "@type": "Question",
    "name": "¿Puedo tramitar mi pasaporte polaco a distancia?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No. Este trámite se realiza únicamente ante un cónsul polaco, principalmente porque el documento es biométrico y usted deberá dejar sus huellas digitales en el sistema consular de su país de residencia."
    }
  },{
    "@type": "Question",
    "name": "¿Cómo puedo inscribir los documentos de registro civil?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "El trámite es fácil y relativamente ágil. Al enviarnos su partida de nacimiento apostillada y un poder para un representante de AMLAT Consulting, nosotros nos encargaremos de su traducción e inscripción."
    }
  },{
    "@type": "Question",
    "name": "¿Qué garantía tengo que el Voivodato de Masovia confirmará que tengo ciudadanía polaca?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "En todos los casos, la decisión sobre el otorgamiento de la confirmación de ciudadanía polaca la toma el órgano autorizado por ley –el Voivodato de Masovia–, cuyas sedes se encuentran en Varsovia, la capital polaca. Antes de firmar el contrato con AMLAT Consulting, le informaremos sobre las posibilidades de recibir tal confirmación. Si vemos que en su caso no tiene sentido solicitarla por falta de pruebas también se lo haremos saber; son pocos los casos así, pero existen. Este tipo de consultas las hacemos antes de firmar un contrato y no cobramos por esta gestión."
    }
  },{
    "@type": "Question",
    "name": "¿Dónde debo realizar la traducción jurada de mis documentos (p. ej. partidas de nacimiento, matrimonio) – en Polonia o en mi país de residencia?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Si el trámite lo realizara por medio de AMLAT Consulting, en Polonia. La razón es muy sencilla: todas las traducciones realizadas por traductores jurados que están registrados en Polonia, serán rechazadas por el Voivodato de Masovia, a menos que estén certificadas por el cónsul polaco. En este caso usted tendrá que pagar el doble: por la traducción en su país de residencia y en el consulado por la certificación. AMLAT Consulting tiene un equipo de traductores jurados registrados y las traducciones (hasta 18 paginas de texto) están incluidas en el precio del trámite."
    }
  }]
}

const Faq = () => {
  return (
    <React.Fragment>
      <MetaComponent
        title="Ciudadanía de Polonia: Nosotros"
        description="Somos un equipo de expertos en tramitación de ciudadanía polaca con experiencia laboral en América Latina de más de 25 años"
        image="https://ciudadaniadepolonia.pl/img/ciudadania-de-polonia-og.jpg"
        data={data}
      />
      <header className="top-nosotros">
        <NavMenu />
      </header>
      <Container maxWidth="lg">
        <main role="main">
          <h1 className="title-6">Preguntas Frecuentes</h1>
          {data.mainEntity.map((obj, idx) => (
              <Accordion
              key={`data-item-1-${idx}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${idx}-content`}
                  id={`panel-${idx}-header`}
                  style={{ backgroundColor: '#c5c5c5', fontFamily: 'Montserrat'}}
                >
                  <Typography style={{fontFamily: 'Montserrat', fontWeight:'Bold'}}>{obj.name}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#f6f6f6' }}>
                  <Typography paragraph style={{fontFamily: 'Montserrat'}}>{obj.acceptedAnswer.text}</Typography>
                </AccordionDetails>
              </Accordion>
          ))}
        </main>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
export default Faq
